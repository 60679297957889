import { useState } from "react";

import { Collapsible } from "@/components/Collabsible.tsx";
import { MenuItem } from "@/components/MenuItem.tsx";
import { BurgerIcon } from "@/components/ui/BurgerIcon.tsx";
import type { MenuItem as MenuItemType } from "@/types";
import { cn } from "@/utils/cn.ts";

type CounterProps = {
  menu: MenuItemType[];
  pathname: string;
};

export default function Counter({ menu, pathname }: CounterProps) {
  const [isActive, setActive] = useState(false);

  if (!menu.length) {
    return null;
  }

  return (
    <>
      <BurgerIcon
        isActive={isActive}
        onClick={() => setActive(!isActive)}
        className="sm:hidden"
      />

      <Collapsible
        className={cn("w-full", "sm:hidden")}
        isOpen={isActive}
        tag="nav"
      >
        <ul className="mt-4">
          {menu.map(
            ({ label, link, authorizedOnly, unauthorizedOnly }, index) => (
              <MenuItem
                key={index}
                authorizedOnly={authorizedOnly}
                unauthorizedOnly={unauthorizedOnly}
              >
                <li>
                  <a
                    href={link}
                    className={cn("block relative py-3 pl-3", {
                      'before:content[""] before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-light-blue':
                        link === pathname,
                    })}
                  >
                    {label}
                  </a>
                </li>
              </MenuItem>
            ),
          )}
        </ul>
      </Collapsible>
    </>
  );
}
